





















































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import PatternFilter from '@/components/PatternFilter.vue'
import ScrollToTop from "@/components/ScrollToTop.vue";
import {round} from "@amcharts/amcharts4/.internal/core/utils/Math";

@Component({
  components: {ScrollToTop, PatternFilter}
})

export default class MultiPatternSolution extends Vue {

  private placeholder = require('@/assets/pervaSafeLogo.png')
  private web_backend = 'https://hci.hochschule-trier.de:8443/files/'

  //Pattern Filter & Search Bar
  private search = ''

  private viewMode = true
  private addMode = false
  private editMode = false
  private detailMode = false

  private mps = []
  private filteredMps = []
  private mpsId = 0
  private mpsName = ""
  private mpsDesc = ""
  private mpsPatterns = ""
  private mpsPatternObjects = []
  private mpsScenarios = ""
  private prevName = ""
  private prevDesc = ""
  private prevPatterns = ""
  private prevScenarios = ""
  private patterns = []
  private scenarios = []

  private chosenPatterns = [] as any
  private chosenScenarios = [] as any

  private accordingComments = []
  private averageRating = 0
  private allRatings = []
  private numberRatings = 0

  private ids = []
  private id = 0
  private patternNames = [] as any
  private commentPatterns = []
  private commentCount = [] as any
  private commentRatings = []
  private avratings = [] as any


  created() {
    this.getMps()
    this.getPatterns()
    this.getScenarios()
  }

  private enterEditMode(name: string){
    this.editMode = true
    this.detailMode = false
    this.viewMode = false
    this.getMps()
    let selectedMps = this.mps.filter((x: any) => (x.name === name))[0] as any
    if(selectedMps){
      this.mpsId = selectedMps.mpsId
      this.mpsName = selectedMps.name
      this.mpsDesc = selectedMps.description
      this.mpsPatterns = selectedMps.patterns
      this.mpsScenarios = selectedMps.scenarios
      this.prevName = selectedMps.name
      this.prevDesc = selectedMps.description
      this.prevPatterns = selectedMps.patterns
      this.prevScenarios = selectedMps.scenarios
      if(selectedMps.patterns){
        this.chosenPatterns = selectedMps.patterns.split(', ')
      }
      this.mpsPatternObjects = this.mpsPatternObjects.filter((x: any) => (this.chosenPatterns.includes(x.name)))
      if(selectedMps.scenarios){
        this.chosenScenarios = selectedMps.scenarios.split(', ')
      }
    }
  }

  private async enterDetailMode(name: string){
    this.detailMode = true
    this.viewMode = false
    this.editMode = false
    this.addMode = false
    await Vue.axios.get(`${this.$store.state.root}/api/mps`).then(resp => {
      this.mps = resp.data
      this.filteredMps = resp.data
    })
    .then(() => {
      this.getPatterns()
      this.getScenarios()
      let selectedMps = this.mps.filter((x: any) => (x.name === name))[0] as any
      if(selectedMps){
        this.mpsId = selectedMps.mpsId
        this.mpsName = selectedMps.name
        this.mpsDesc = selectedMps.description
        this.mpsPatterns = selectedMps.patterns
        this.mpsScenarios = selectedMps.scenarios
        this.mpsPatternObjects = this.patterns
        if(selectedMps.patterns){
          this.chosenPatterns = selectedMps.patterns.split(', ')
          this.mpsPatternObjects = this.mpsPatternObjects.filter((x: any) => (this.chosenPatterns.includes(x.name)))
        }
        if(selectedMps.scenarios){
          this.chosenScenarios = selectedMps.scenarios.split(', ')
        }
      }
    })
  }


  private passPatternData(name: string) {
    this.$emit('emitPatternData', {patternName: name, webpage: 'MPS'})
  }

  private shortenDetailText(text: string) {
    if (text == null || text == '' || text.length < 150) {
      return text
    }
    return text.slice(0, 150) + " ..."
  }

  private async getMps(){
    Vue.axios.get(`${this.$store.state.root}/api/mps`).then(resp => {
      this.mps = resp.data
      this.filteredMps = resp.data
    })
  }

  private filterMps() {
    this.filteredMps = this.mps.filter((c: any) => (c.name.toLowerCase().includes(this.search.toLowerCase().trim())))
  }

  private async getPatterns() {
    Promise.all([
      fetch(`${this.$store.state.root}/api/patterns/`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/comments/`, {
        method: 'GET'
      })
    ]).then(async allResponses => {
      const patternData = await allResponses[0].json()
      this.patterns = patternData
      this.ids = patternData.map((c: any) => c.patternId as [])
      this.patternNames = patternData.map((n: any) => n.name as [])

      this.patterns.forEach((pattern: any) => {
        pattern.image = this.web_backend + pattern.image
      })

      const commentData = await allResponses[1].json()
      this.accordingComments = commentData.filter((c: any) => (c.pattern == this.id))
      this.allRatings = this.accordingComments.map((int: any) => int.ratingRelevance) as []
      const sum = this.allRatings.reduce((a: any, b: any) => a + b, 0)
      this.averageRating = (sum / this.allRatings.length) || 0
      this.numberRatings = this.allRatings.length

      this.commentPatterns = commentData.map((i: any) => i.pattern as [])
      this.commentRatings = commentData.map((r: any) => r.ratingRelevance as [])

      //  Count how many comments are in database for each pattern
      for (let i = 0; i < this.ids.length; i++) {
        let tmp = 0
        for (let j = 0; j < this.commentPatterns.length; j++) {
          if (this.ids[i] == this.commentPatterns[j]) {
            tmp++
          }
        }
        this.commentCount.push(tmp)
      }

      // calculate aver. rating for each Pattern
      for (let i = 0; i < this.ids.length; i++) {
        let rating = 0
        let anz = 0
        for (let j = 0; j < this.commentPatterns.length; j++) {
          if (this.ids[i] == this.commentPatterns[j]) {
            rating += this.commentRatings[j]
            anz++
          }
        }
        let tmp = round(rating / anz, 1)
        if (Number.isNaN(tmp)) {
          tmp = 0
        }
        this.avratings.push(tmp)
      }
    })
  }

  private getPatternImg(name: string) {
    let matchingPatterns = this.patterns.filter((pattern: any) => (pattern.name == name)).map((x: any) => x.image)
    let patternImg = matchingPatterns[0]
    if(typeof patternImg !== "undefined"){
      return patternImg
    }
    else return "placeholder.webp"
  }

  private hasUnsavedChanges() {
    return this.prevName != this.mpsName || this.prevDesc != this.mpsDesc ||
        this.prevPatterns != this.mpsPatterns || this.prevScenarios != this.mpsScenarios;

  }

  private goBack(){
    if(this.detailMode){
      this.detailMode = false
      this.viewMode= true
      this.editMode = false
      this.addMode = false
      this.mpsName = ''
      this.mpsDesc = ''
      this.chosenPatterns = []
      this.chosenScenarios = []
    }
    else if(this.editMode){
      if(this.hasUnsavedChanges()) {
        this.$buefy.dialog.confirm({
          title: ('Back'),
          message: 'You\'ve made unsaved changes. Are you sure you want to <b>go back and discard Changes</b>? This action cannot be undone.',
          cancelText: 'Continue Editing',
          confirmText: 'Back and Discard',
          type: 'is-danger',
          hasIcon: true,
          // overwrite changes with prev data (before entering Edit-mode)
          onConfirm: () => {
            this.enterDetailMode(this.mpsName)
            // Arg applyChanges = false to discard changes and overwrite with prevData

          }
        })
      }
      else {
        this.enterDetailMode(this.mpsName)
        this.editMode = false
        this.chosenPatterns = []
        this.chosenScenarios = []
      }
    }
    else if(this.addMode){
      if(this.hasUnsavedChanges()) {
        this.$buefy.dialog.confirm({
          title: ('Back'),
          message: 'You\'ve made unsaved changes. Are you sure you want to <b>go back and discard Changes</b>? This action cannot be undone.',
          cancelText: 'Continue Editing',
          confirmText: 'Back and Discard',
          type: 'is-danger',
          hasIcon: true,
          // overwrite changes with prev data (before entering Edit-mode)
          onConfirm: () => {
            this.viewMode = true
            this.addMode = false
            this.editMode = false
            this.detailMode = false
            this.mpsName = ''
            this.mpsDesc = ''
            this.chosenPatterns = []
            this.chosenScenarios = []
            // Arg applyChanges = false to discard changes and overwrite with prevData
          }
        })
      }
      else {
        this.viewMode = true
        this.addMode = false
        this.editMode = false
        this.detailMode = false
        this.mpsName = ''
        this.mpsDesc = ''
        this.chosenPatterns = []
        this.chosenScenarios = []
      }
    }
  }

  private async addMps(){
    if(this.mpsName.toString()!= ""){
      let p = null
      p = this.chosenPatterns.join(", ")
      this.chosenPatterns = []
      let s = null
      s = this.chosenScenarios.join(", ")
      this.chosenScenarios = []

      let mps = {
        name : this.mpsName,
        description : this.mpsDesc,
        patterns : p,
        scenarios : s
      }
      Vue.axios.post(`${this.$store.state.root}/api/mps`, mps)
          .then(() => {
            this.$buefy.toast.open({
              message: ("Multi-Pattern Solution was saved"),
              position: 'is-top',
              type: 'is-success'
            })
            this.mpsName = ''
            this.mpsDesc = ''
            this.chosenPatterns = []
            this.chosenScenarios = []
            this.getMps()
            this.viewMode = true
            this.addMode = false
          })
    }else {
      this.$buefy.toast.open({
        message: `Please enter a name first`,
        position: 'is-top',
        type: 'is-danger'
      })
    }

  }

  private async updateMps(){
    if(this.mpsName.toString()!= ""){
      let p = null
      p = this.chosenPatterns.join(", ")
      let s = null
      s = this.chosenScenarios.join(", ")
      let mps = {
        name : this.mpsName,
        description : this.mpsDesc,
        patterns : p,
        scenarios : s
      }
      await Vue.axios.put(`${this.$store.state.root}/api/mps/${this.mpsId}`, mps)
          .then(() => {
            this.$buefy.toast.open({
              message: ("Multi-Pattern Solution was saved"),
              position: 'is-top',
              type: 'is-success'
            })
          })
          .then(() => {
            this.getMps()
          })
          .then(() => {
            this.enterDetailMode(this.mpsName)
          })
    }else {
      this.$buefy.toast.open({
        message: `Please enter a name first`,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }

  private deleteMps() {
    this.$buefy.dialog.confirm({
      title: ('Deleting ' + this.mpsName.toString()),
      message: 'Are you sure you want to <b>delete ' + this.mpsName.toString()
          + '</b>? This action cannot be undone.',
      confirmText: 'Delete ',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        Vue.axios.delete(`${this.$store.state.root}/api/mps/${this.mpsId}`)
            .then(async () => {
              this.$buefy.toast.open({
                message: (this.mpsName.toString() + ` was deleted`),
                position: 'is-top',
                type: 'is-danger'
              })
              this.getMps()
              this.editMode = false
              this.detailMode = false
              this.addMode = false
              this.viewMode = true
              this.mpsName = ''
              this.mpsDesc = ''
              this.chosenPatterns = []
              this.chosenScenarios = []
            })
      }
    })
  }

  private async getScenarios() {
    Vue.axios.get(`${this.$store.state.root}/api/scenarios`).then(resp => {
      this.scenarios = resp.data
    })
  }

  private getCommentsCount(name: String) {
    return this.commentCount[this.patternNames.indexOf(name)]
  }

  private getAverageRating(name: String) {
    return this.avratings[this.patternNames.indexOf(name)]
  }

  private getStageName(stage: number) {
    switch (stage) {
      case 0:
        return 'Created'
      case 1:
        return 'Consideration'
      case 2:
        return 'Candidate'
      case 3:
        return 'Approved'
      case 4:
        return 'Rejected'
      default:
        return '-'
    }
  }
}
